var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AllInactiveMissions"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":true,"color":"black"},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('h1',[_vm._v(" Missions archivées du cabinet ")])]),_c('CCol',{staticClass:"text-right align-self-center",attrs:{"sm":"6"}},[_c('CButton',{attrs:{"size":"sm","shape":"pill","color":"outline-success"},on:{"click":function($event){return _vm.$router.push('/add-new-mission')}}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Créer une nouvelle mission ")],1)],1)],1),_c('CCard',{staticClass:"mt-3"},[_c('CCardBody',[_c('CDataTable',{staticClass:"cursor-table",attrs:{"headVariant":"light","hover":"","striped":"","items":_vm.inactiveMissionsRender,"fields":_vm.inactiveMissionsRenderFields,"noItemsView":{ noResults: "Aucun résultat", noItems: "Aucun résultat" }},on:{"row-clicked":_vm.goToMissionPage},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(item.name))]),(item.is_paiement_alert)?_c('div',[_c('em',{staticClass:"text-danger"},[_vm._v("Alerte de paiement levée")])]):_vm._e()])]}},{key:"types",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.types),function(type){return _c('em',{key:type.id},[_vm._v(_vm._s(type.name)+" ")])}),0)]}},{key:"collaborateurs",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.collaborateurs),function(collaborateur){return _c('em',{key:collaborateur.id},[_vm._v(_vm._s(collaborateur.full_name)+", ")])}),0)]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('small',[_vm._v(" "+_vm._s(item.description))])])]}}])}),_c('CRow',[_c('CCol',{staticClass:"text-center"},[(_vm.getAllInactiveMissionsUrl)?_c('CButton',{staticClass:"px-4",attrs:{"color":"outline-dark"},on:{"click":function($event){return _vm.getAllInactiveMissions()}}},[_vm._v(" Voir plus ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }