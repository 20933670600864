<template>
  <div class="AllInactiveMissions">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow>
      <CCol sm="6">
        <h1> Missions archivées du cabinet </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="$router.push('/add-new-mission')"
          size="sm"
          shape="pill"
          color="outline-success">
            <CIcon name="cil-plus"/> Créer une nouvelle mission
        </CButton>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>

        <CDataTable
          class="cursor-table"
          headVariant="light"
          hover
          striped
          :items="inactiveMissionsRender"
          :fields="inactiveMissionsRenderFields"
          @row-clicked="goToMissionPage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >

        <template #name="{item}">
          <td>
           <span>{{item.name}}</span>
           <div v-if="item.is_paiement_alert">
             <em class="text-danger">Alerte de paiement levée</em>
           </div>
          </td>
        </template>

        <template #types="{item}">
          <td>
            <em v-for="type in item.types" v-bind:key="type.id">{{type.name}} </em>
          </td>
        </template>

        <template #collaborateurs="{item}">
          <td>
            <em v-for="collaborateur in item.collaborateurs" v-bind:key="collaborateur.id">{{collaborateur.full_name}}, </em>
          </td>
        </template>

        <template #description="{item}">
          <td>
            <small> {{item.description}}</small>
          </td>
        </template>

        </CDataTable>
        <CRow>
          <CCol class="text-center">
            <CButton
              v-if="getAllInactiveMissionsUrl"
              @click="getAllInactiveMissions()"
              class="px-4"
              color="outline-dark">
              Voir plus
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'

import { APIManagerConnected } from '@/api/APIManagerConnected'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiManagerConnected = new APIManagerConnected()

export default {
  name: 'AllInactiveMissions',
  components: {
    Loading,
  },
  data: function () {
    return {
      // ------------- MISSION -----------
      inactiveMissions: [],
      inactiveMissionsRender: [],
      inactiveMissionsRenderFields: [
        { key: "client_name", label: "Nom du client", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "name", label: "Intitulé mission", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "types", label: "Type de mission", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "referent", label: "Référent mission", tdClass: 'ui-helper-center', _style: "min-width: 150px;"},
        { key: "collaborateurs", label: "Collaborateurs affectés", tdClass: 'ui-helper-center', _style: "min-width: 200px;" },
        { key: "description", label: "Description", tdClass: 'ui-helper-center', _style: "min-width: 200px;"},
      ],
      isAllInactiveMissionsLoading: false,


      basegetAllInactiveMissionsUrl: apiBaseUrl + '/all-inactive-missions',
      getAllInactiveMissionsUrl: apiBaseUrl + '/all-inactive-missions',
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllInactiveMissionsLoading) {
        return true
      }
      return false
    }
  },
  created: function() {
    this.getAllInactiveMissions()
  },
  watch: {
    inactiveMissions: function (newMissions) {
      if (newMissions.length == 0) {
        this.inactiveMissionsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newMissions.length; i++) {
          final_array.push(
            {
              'id': newMissions[i].id,
              'client_name': newMissions[i].client_name,
              'name': newMissions[i].name,
              'types': newMissions[i].types,
              'referent': newMissions[i].referent_full_name,
              'collaborateurs': newMissions[i].collaborateurs,
              'description': newMissions[i].description,
              'is_paiement_alert': newMissions[i].is_paiement_alert
            }
          )
        }
        this.inactiveMissionsRender = final_array
      }
    },
  },
  methods: {
    getAllInactiveMissions () {
      this.isAllInactiveMissionsLoading = true

      apiManagerConnected.getAllInactiveMissions(this.token, this.getAllInactiveMissionsUrl)
      .then((result) => {
        this.inactiveMissions = this.inactiveMissions.concat(result.data.results)
        this.getAllInactiveMissionsUrl = result.data.next
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllInactiveMissionsLoading = false
      })
    },

    // ------------ ROUTER FUNCTIONS -------------------
    goToMissionPage(item) {
      this.$router.push('/mission/' + item.id)
    }

  }

}
</script>
